<template>
  <div class="pa-3">
    <AnalysisItem
      v-for="item in analysisList"
      :key="item.key"
      :title="item.title"
      :kind="item.key"
      :displayDataTypes="displayDataTypes[item.key]"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AnalysisItem from '@/components/Analysis/AnalysisItem'

export default {
  components: {
    AnalysisItem
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      analysisList: [
        { key: 'meter', title: '電表分析' },
        { key: 'ac', title: '冷氣分析' },
        { key: 'env', title: '環境分析' }
        // { key: 'air', title: '新風數據' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      meterTabTypes: 'analysis/meter',
      acTabTypes: 'analysis/ac',
      envTabTypes: 'analysis/env',
      airTypes: 'analysis/air',
      classroomMeters: 'analysis/classroomMeters'
    }),

    displayDataTypes() {
      return {
        meter: this.meterTabTypes.filter(({ optionTypes }) =>
          optionTypes.find((type) => type === 'classMeter')
        ),
        ac: this.acTabTypes,
        env: this.envTabTypes,
        air: this.airTypes
      }
    }
  },
  async mounted() {
    if (
      !this.classroomMeters ||
      this.classroomMeters[0]?.classroomId !== this.tagId
    )
      this.getClassroomMeters(this.tagId)
  },
  methods: {
    ...mapActions({
      getClassroomMeters: 'analysis/getClassroomMeters'
    })
  }
}
</script>
