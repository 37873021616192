<template>
  <div class="pa-3">
    <h3 class="mt-1 mb-2 accent--text font-weight-bold">{{ title }}</h3>
    <v-card class="mx-auto frosted-glass" elevation="0">
      <v-tabs
        v-model="bindTab"
        background-color="ems-light-periwinkle"
        color="accent"
        align-with-title
        show-arrows
      >
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab
          v-for="item in displayDataTypes"
          active-class="white font-weight-bold"
          :key="item.id"
        >
          <div v-if="item.label === 'CO2'">CO<sub>2</sub></div>
          <div v-else>
            {{ item.label }}
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="bindTab">
        <v-tab-item v-for="(item, index) in displayDataTypes" :key="item.id">
          <AnalysisSearch
            :displayDataType="item"
            :tabIndex="index"
            :kind="kind"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import AnalysisSearch from '@/components/Analysis/AnalysisSearch'

export default {
  components: {
    AnalysisSearch
  },
  props: {
    title: {
      type: String,
      default: '-'
    },
    displayDataTypes: {
      type: Array,
      default: () => []
    },
    kind: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bindTab: 0
    }
  }
}
</script>
